import React, { useState } from 'react'
import { Card, CardBody, FormGroup, Input, Label } from 'reactstrap'
import ForgotPassword from './ForgotPassword';
import ThrobbleHelper from '../helpers/ThrobbleHelper';
import { fieldRequired } from '../helpers/validation';
import swal from 'sweetalert';
import { getApiUrl, getAppVersion } from '../helpers/lookup';
import { setAuthToken, setFullname, setIsLoggedIn, setSupplierName } from '../helpers/authentication';

export default function Login() {

    const [Username, setUsername] = useState("");
    const [Password, setPassword] = useState("");
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showThrobble, setShowThrobble] = useState(false);
    const [throbbleMessage, setThrobbleMessage] = useState("");

    const validateLoginDetails = () => {
        const isUsernameValid = fieldRequired(Username, "errUsername", "required");
        const isPasswordValid = fieldRequired(Password, "errPassword", "required");
        return isUsernameValid && isPasswordValid;
    };

    const setThrobble = (displayThrobble, throbbleMsg = "") => {
        setThrobbleMessage(throbbleMsg);
        setShowThrobble(displayThrobble);
    };

    const LoginUser = async (evt) => {
        evt.stopPropagation();

        try {
            if (validateLoginDetails()) {
                setThrobble(true, "Logging in");
                const response = await fetch(
                    `${getApiUrl()}/login/UserLogin?username=${Username}&password=${Password}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (response.ok) {
                    const data = await response.json();
                    console.log(data);
                    setAuthToken(data.item1);
                    setFullname(data.item2);
                    setSupplierName(data.item3);
                    setIsLoggedIn(true);
                    window.location.href = "/";
                } else {
                    setThrobble(false);
                    setPassword("");
                    switch (response.status) {
                        case 406:
                            swal(
                                "Account Suspended".toUpperCase(),
                                "This account has been suspended. Please contact admin for assistance.",
                                "error"
                            );
                            break;
                        default:
                            swal(
                                "Login Failed".toUpperCase(),
                                "Username or Password is wrong. Please try again",
                                "error"
                            );
                    }
                }
            }
        } catch (error) {
            setThrobble(false);
            swal(
                "Login Failed",
                "Username or Password is wrong. Please try again",
                "error"
            );
        }
    };

    const forgotPasswordClick = (evt) => {
        evt.preventDefault();
        setShowPasswordModal(true);
    };

    return (
        <Card className='shadow-lg bg-body rounder'>
            <CardBody>
                <h3 className="text-uppercase mb-4">Login</h3>
                <FormGroup>
                    <Label>
                        Username
                        <span className="required-icon">*</span>
                        <span id="errUsername" className="ErrorText" />
                    </Label>
                    <Input type="email" bsSize="sm" onChange={(e) => { setUsername(e.target.value); fieldRequired(e.target.value, "errUsername", "required"); }} />
                </FormGroup>
                <FormGroup>
                    <Label>
                        Password
                        <span className="required-icon">*</span>
                        <span id="errPassword" className="ErrorText" />
                    </Label>
                    <Input type="password" bsSize="sm" value={Password} onChange={(e) => { setPassword(e.target.value); fieldRequired(e.target.value, "errPassword", "required"); }} />
                </FormGroup>

                <div className="d-flex justify-content-between mb-3">
                    <small className="align-self-end">
                        <a className="" onClick={forgotPasswordClick}>
                            Forgot Password?
                        </a>
                        {/* <br />
                        No account? <a href="/register">Register</a> */}
                    </small>
                    <div className="text-end">
                        <button type="button" className="btn btn-purple" onClick={LoginUser}><i className="fas fa-sign-in-alt me-2"></i>Login</button>
                    </div>
                </div>
                <hr />
                <div className="small">
                    <a href="https://estafrica.co.za/privacy-policy/" target="_blank" rel="noreferrer noopener">Privacy Policy</a>
                </div>
                <div className="fixed-bottom text-purple text-center pb-3">
                    Version: {getAppVersion()}
                </div>

            </CardBody>
            <ForgotPassword toggleShowPassword={setShowPasswordModal} showModal={showPasswordModal} />
            <ThrobbleHelper showThrobble={showThrobble} throbbleMessage={throbbleMessage} />
        </Card>
    )
}

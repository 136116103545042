/* eslint-disable eqeqeq */

export const getApiUrl = () => {
  const NODE_ENV = process.env.NODE_ENV || "development";
  if (NODE_ENV === "development")
    return "https://localhost:7151/api"
  else
    return "https://est-events.purplecosmos.co.za/api";
};

export const getAppVersion = () => {
  return "1.1.0";
};

export const getAppVersionFromLocalStorage = () => {
  if (window.localStorage.getItem("AppVersion") !== null) {
    const data = JSON.parse(window.localStorage.getItem("AppVersion"));
    return data;
  }

  return null;
}

export const setAppVersion = (appVersion) => {
  try {
    window.localStorage.setItem("AppVersion", JSON.stringify(appVersion));
    return true;
  } catch (error) {
    return false;
  }
}

export const getUser = () => {
  if (window.localStorage.getItem("CurrentUser") != null) {
    var data = JSON.parse(window.localStorage.getItem("CurrentUser"));
    return data;
  }
  else
    return null;
};

export const setUser = (User) => {

  try {
    window.localStorage.setItem("CurrentUser", JSON.stringify(User));
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getUserId = () => {
  if (window.localStorage.getItem("CurrentUserId") != null) {
    return window.localStorage.getItem("CurrentUserId");
  }
  else
    return null;
};

export const setUserId = (UserId) => {

  try {
    window.localStorage.setItem("CurrentUserId", UserId);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getUserEmail = () => {
  if (window.localStorage.getItem("UserEmail") != null) {
    var data = JSON.parse(window.localStorage.getItem("UserEmail"));
    return data;
  }
  else
    return null;
};

export const setUserEmail = (User) => {

  try {
    window.localStorage.setItem("UserEmail", JSON.stringify(User));
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getCompanyName = () => {
  if (window.localStorage.getItem("CompanyName")) {
    var data = JSON.parse(window.localStorage.getItem("CompanyName"));
    return data;
  }
  else
    return null;
};

export const setCompanyName = (User) => {

  try {
    window.localStorage.setItem("CompanyName", JSON.stringify(User));
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getCreatedAt = () => {
  if (window.localStorage.getItem("CreatedAt")) {
    var data = JSON.parse(window.localStorage.getItem("CreatedAt"));
    return data;
  }
  else
    return null;
};

export const setCreatedAt = (User) => {

  try {
    window.localStorage.setItem("CreatedAt", JSON.stringify(User));
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};


export const getTimerId = () => {
  if (window.sessionStorage.getItem("TimerId"))
    return window.sessionStorage.getItem("TimerId");
  else
    return null;
}

export const setTimerId = (id) => {
  try {
    window.sessionStorage.setItem("TimerId", id);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export const GetIsScanning = () => {
  if (window.sessionStorage.getItem("IsScanning")) {
    console.log(window.sessionStorage.getItem("IsScanning"));
    return window.sessionStorage.getItem("IsScanning") == true;
  } else
    return false;
}

export const SetIsScanning = (data) => {
  try {
    window.sessionStorage.setItem("IsScanning", data);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export const GetProvinceList = () => {
  return [
      { value: 1, text: "Eastern Cape" },
      { value: 2, text: "Free State" },
      { value: 3, text: "Gauteng" },
      { value: 4, text: "KwaZulu-Natal" },
      { value: 5, text: "Limpopo" },
      { value: 6, text: "Mpumalanga" },
      { value: 7, text: "Northern Cape" },
      { value: 8, text: "North West" },
      { value: 9, text: "Western Cape" },
  ];
}